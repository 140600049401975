import { Col } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { Text } from "@amzn/stencil-react-components/text";
import React from "react";
import { FormattedMessage } from "react-intl";

import PostLoginWindowsConnectVpn from "@/assets/images/postLoginWindowsConnectVpn.png";
import PostLoginWindowsSecurityPin from "@/assets/images/postLoginWindowsSecurityPIN.png";
import PostLoginWindowsSelectPreLogin from "@/assets/images/postLoginWindowsSelectPreLogin.png";
import ImagesRow from "@/components/Commons/ImagesRow";
import { OrderedList } from "@/components/Commons/OrderedList";
import { OPERATING_SYSTEM } from "@/helpers/os-helper";

/**
 * Show a list of instructions for completing the post-login setup on day-1.
 */
export default function PostLoginInstructions({
  username,
  os,
}: {
  username?: string;
  os: string;
}): JSX.Element {
  const isMac = os === OPERATING_SYSTEM.MAC;
  const newHirePortalLinkUrl = "https://it.amazon.com/newhire";

  if (isMac) {
    return (
      <OrderedList
        formatMessagesListProps={[
          { id: "allStepsCompleted-mac-postLoginStep1" },
          { id: "allStepsCompleted-mac-postLoginStep2" },
        ]}
        dataTestId="postLoginMacSteps"
      />
    );
  } else {
    return (
      <Col gridGap="S400" dataTestId="postLoginWinsSteps">
        <Col gridGap="S300">
          <Text fontWeight="light">
            <em>
              <FormattedMessage id="allStepsCompleted-win-instructionSent" />
            </em>
          </Text>
          <Text fontWeight="medium">
            <FormattedMessage id="allStepsCompleted-win-step1" />
          </Text>
          <ol
            className="postLoginStepsList"
            data-test-id="postLoginWinsStep1List"
          >
            <Col gridGap="S200">
              <li>
                <Text fontSize="T300" fontWeight="light">
                  <FormattedMessage id="allStepsCompleted-win-step1-subStep1" />
                </Text>
              </li>
              <li>
                <Col gridGap="S300">
                  <Text fontSize="T300" fontWeight="light">
                    <FormattedMessage id="allStepsCompleted-win-step1-subStep2" />
                  </Text>
                  <Text fontSize="T300" fontWeight="light">
                    <FormattedMessage id="allStepsCompleted-win-step1-subStep2-note" />
                  </Text>
                  <ImagesRow
                    images={[
                      {
                        src: PostLoginWindowsConnectVpn,
                        altTextLocaleId:
                          "allStepsCompleted-windows-vpnIcon-altText",
                        widthPx: 312,
                        heightPx: 124,
                      },
                    ]}
                  />
                </Col>
              </li>
              <li>
                <Text fontSize="T300" fontWeight="light">
                  <FormattedMessage id="allStepsCompleted-win-step1-subStep3" />
                </Text>
              </li>
              <li>
                <Col gridGap="S300">
                  <Text fontSize="T300" fontWeight="light">
                    <FormattedMessage id="allStepsCompleted-win-step1-subStep4" />
                  </Text>
                  <ImagesRow
                    images={[
                      {
                        src: PostLoginWindowsSelectPreLogin,
                        altTextLocaleId:
                          "allStepsCompleted-win-step1-preLogin-altText",
                        widthPx: 1269,
                        heightPx: 639,
                      },
                    ]}
                  />
                </Col>
              </li>
              <li>
                <Text fontSize="T300" fontWeight="light">
                  <FormattedMessage
                    id="allStepsCompleted-win-step1-subStep5"
                    values={{ username }}
                  />
                </Text>
              </li>
              <li>
                <Col gridGap="S300">
                  <Text fontSize="T300" fontWeight="light">
                    <FormattedMessage
                      id="allStepsCompleted-win-step1-subStep6"
                      values={{ username }}
                    />
                  </Text>
                  <ImagesRow
                    images={[
                      {
                        src: PostLoginWindowsSecurityPin,
                        altTextLocaleId:
                          "allStepsCompleted-win-step1-securityKey-altText",
                        widthPx: 402,
                        heightPx: 290,
                      },
                    ]}
                  />
                </Col>

                <Text fontWeight="light">
                  <FormattedMessage id="allStepsCompleted-win-step1-subStep6-note" />
                </Text>
              </li>
              <li>
                <Text fontSize="T300" fontWeight="light">
                  <FormattedMessage id="allStepsCompleted-win-step1-subStep7" />
                </Text>
              </li>
            </Col>
          </ol>
        </Col>
        <Col gridGap="S300" dataTestId="postLoginWinsStep2">
          <Text fontWeight="medium">
            <FormattedMessage id="allStepsCompleted-win-step2" />
          </Text>
          <Col>
            <Text fontWeight="light">
              <FormattedMessage id="allStepsCompleted-win-step2-intro" />
            </Text>
            <Text fontWeight="medium">
              <FormattedMessage
                id="amazonUsername-expander-body-username"
                values={{ username }}
              />
            </Text>
          </Col>
          <ol data-test-id="postLoginWinsStep2List">
            <li>
              <Text fontSize="T300" fontWeight="light">
                <FormattedMessage
                  id="allStepsCompleted-win-step2-subStep1"
                  values={{ username }}
                />
              </Text>
            </li>
            <li>
              <Col gridGap="S300">
                <Text fontSize="T300" fontWeight="light">
                  <FormattedMessage id="allStepsCompleted-win-step2-subStep2" />
                </Text>
                <Text fontSize="T300" fontWeight="light">
                  <FormattedMessage id="allStepsCompleted-win-step2-subStep2-note1" />
                </Text>
                <Text fontSize="T300" fontWeight="light">
                  <FormattedMessage id="allStepsCompleted-win-step2-subStep2-note2" />
                </Text>
              </Col>
            </li>
          </ol>
        </Col>
        <Col gridGap="S300" dataTestId="postLoginWinsStep3">
          <Text fontWeight="medium">
            <FormattedMessage id="allStepsCompleted-win-step3" />
          </Text>
          <Text fontWeight="light">
            <FormattedMessage
              id="allStepsCompleted-win-step3-intro"
              values={{
                newHirePortalLink: (
                  <Link
                    className="wizard-link"
                    textDecoration="none"
                    href={newHirePortalLinkUrl}
                    target="_blank"
                    rel="noreferrer"
                    fontWeight="light"
                  >
                    {newHirePortalLinkUrl}
                  </Link>
                ),
              }}
            />
          </Text>
        </Col>
      </Col>
    );
  }
}
